import { RedirectRequest, PopupRequest, AccountInfo } from '@azure/msal-browser';
import {
  AuthData,
  Notification,
  User,
  ContactUsData,
  LoginData,
  ExternalApiDetails,
  CreateApplicationData,
  SignupData,
  UriData,
  UpdateUserData,
  GenerateClientIdData,
  UpdateAppWebsiteData,
  AddUserToTeamGroupType,
  PromoteRemoveUserInTeamGroup,
} from './index';

export class ShowNavbarText {
  static readonly type = '[Navbar] Show Text';
}

export class SetSignupData {
  static readonly type = '[SignupData] Set';

  constructor(public signupData: any) {}
}

export class Login {
  static readonly type = '[Login] Login';

  constructor(public loginData: LoginData) {}
}
export class SetResetPasswordEmail {
  static readonly type = '[ResetPasswordEmal] Set';

  constructor(public email: string) {}
}

export class SendResetPasswordEmail {
  static readonly type = '[ResetPasswordEmal] send';

  constructor(public email: string) {}
}

export class GetExternalApiData {
  static readonly type = '[ExternalApiData] Get';

  constructor() {}
}

export class GetExternalApplicationsData {
  static readonly type = '[ExternalApplicationsData] Get';

  constructor() {}
}

export class SubscribeToOneApiForManyApplications {
  static readonly type = '[SubscribeToApi] Set';

  constructor(
    public apiId: string,
    public applicationIds: string[]
  ) {}
}

export class SubscribeToManyApisForOneApplication {
  static readonly type = '[SubscribeToManyApisForOneApplication] PUT';

  constructor(
    public applicationId: string,
    public apis: string[]
  ) {}
}

export class SetSelectedRoute {
  static readonly type = '[SelectedRoute] Set';

  constructor(public route: string) {}
}

export class RemoveNotification {
  static readonly type = '[Notification] Remove';

  constructor(public notification: Notification) {}
}

export class RemoveNotifications {
  static readonly type = '[Notifications] Remove';

  constructor(public notifications: Notification[]) {}
}

export class SetNotification {
  static readonly type = '[Notification] Set';

  constructor(public notification: Notification) {}
}

export class SetAuthData {
  static readonly type = '[AuthData] Set';

  constructor(public authData: AuthData) {}
}

export class CreateUser {
  static readonly type = '[User] Create';

  constructor(public signupData: SignupData) {}
}

export class SetUser {
  static readonly type = '[User] Set';

  constructor(public user: User) {}
}
export class UpdateUser {
  static readonly type = '[User] Update';

  constructor(public updateUserData: UpdateUserData) {}
}

export class GetUserFromSession {
  static readonly type = '[Cache] Get';

  constructor(public account: AccountInfo) {}
}
export class GetCachedUser {
  static readonly type = '[User] Get';

  constructor(public user: User) {}
}
export class AzureLogin {
  static readonly type = '[Azure] Login';

  constructor(public userFlowRequest: RedirectRequest | PopupRequest) {}
}

export class AzureLogout {
  static readonly type = '[Azure] Logout';

  constructor(public userFlowRequest: RedirectRequest | PopupRequest) {}
}

export class AzureSignup {
  static readonly type = '[Azure] Signup';

  constructor(public userFlowRequest: RedirectRequest | PopupRequest) {}
}
export class SetSelectedApi {
  static readonly type = '[SelectedApi] Set';

  constructor(public selectedApiDetail: ExternalApiDetails) {}
}

export class GetApiDetail {
  static readonly type = '[ApiDetail] Get';

  constructor(public id: string) {}
}

export class InitSso {
  static readonly type = '[Sso] Init';

  constructor() {}
}

export class CreateApplication {
  static readonly type = '[SelectedApi] Set';

  constructor(public createApplicationData: CreateApplicationData) {}
}

export class GetApplicationDetails {
  static readonly type = '[AppDetail] Get';

  constructor(public appId: string) {}
}

export class UpdateAppWebsite {
  static readonly type = '[UpdateAppWebsite] Update';

  constructor(public updateAppWebsite: UpdateAppWebsiteData) {}
}
export class AddUri {
  static readonly type = '[AppDetail] Update';

  constructor(public addUriData: UriData) {}
}

export class SetSelectedApplication {
  static readonly type = '[SelectedApplication] Set';

  constructor(public app: any) {}
}

export class SetClientSecret {
  static readonly type = '[SetClientSecret] Set';

  constructor(public secret: string) {}
}

export class ResetClientSecret {
  static readonly type = '[ClientSecret] Reset';

  constructor() {}
}
export class ResetSelectedApplication {
  static readonly type = '[ReselectedApplication] Set';

  constructor() {}
}
export class SetSelectedApis {
  static readonly type = '[SetSelectedApis] Set';

  constructor(public apis: any) {}
}

export class SendContactUsEmail {
  static readonly type = '[SendContactUsEmail] Send';

  constructor(public contactUsData: ContactUsData) {}
}

export class DownloadDocument {
  static readonly type = '[DownloadDocument] Get';

  constructor(
    public serviceID: string,
    public documentID: string
  ) {}
}

export class UnSubscribeAPI {
  static readonly type = '[UnSubscribeAPI] PUT';

  constructor(
    public appId: string,
    public serviceId: string
  ) {}
}

export class GetApplicationByID {
  static readonly type = '[GetApplicationByID] GET';

  constructor(public appId: string) {}
}

export class DeleteApplication {
  static readonly type = '[DeleteApplication] DELETE';

  constructor(public appId: string) {}
}
export class GenerateClientId {
  static readonly type = '[GenerateClientId] POST';

  constructor(public generateClientIdData: GenerateClientIdData) {}
}

export class RegenerateClientId {
  static readonly type = '[RegenerateClientId] POST';

  constructor(public regenerateClientIdData: GenerateClientIdData) {}
}

export class GetContactUsCategories {
  static readonly type = '[ContactUsCategories] GET';

  constructor(public supportRequestApi: string) {}
}

export class SetTeamGroup {
  static readonly type = '[SetTeamGroup] Set';

  constructor(public setTeamGroup: any) {}
}

export class AddUserToTeamGroup {
  static readonly type = '[AddUserToTeamGroup] PUT';
  constructor(public addUserToTeamGroup: AddUserToTeamGroupType) {}
}

export class RemoveUserFromTeamGroup {
  static readonly type = '[RemoveUserFromTeamGroup] PUT';
  constructor(public removeUserFromTeamGroup: PromoteRemoveUserInTeamGroup) {}
}

export class PromoteUserToOwner {
  static readonly type = '[PromoteUserToOwner] PUT';
  constructor(public promoteRemoveUserInTeamGroup: PromoteRemoveUserInTeamGroup) {}
}
