import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AddUserToTeamGroupType,
  Application,
  CombineApplicationsView,
  ContactUsData,
  CreateApplicationData,
  ExternalApiDetails,
  PromoteRemoveUserInTeamGroup,
  SignupData,
  UpdateAppWebsiteData,
} from '../store';
import { UserContextService } from './user-context.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private http: HttpClient,
    private userContextService: UserContextService
  ) {}

  getExternalApiData(): Observable<any> {
    return this.http
      .get('/api/ext/services', {
        headers: {
          'X-User': this.userContextService.getTokenInfo()?.token,
        },
      })
      .pipe(map((result: any[]) => result));
  }

  subscribeToOneApiForManyApplications(apiId: string, applications: string[]): Observable<any> {
    const body = {
      applicationIds: applications,
      receiveEmailNotifications: true,
    };
    return this.http.put(`/api/ext/services/${apiId}/servicesubscription`, body);
  }

  subscribeToManyApisForOneApplication(applicationId: string, apis: string[]): Observable<any> {
    const body = {
      serviceIds: apis,
      receiveEmailNotifications: true,
    };
    // apis.forEach((apiId: any) => {
    //   console.log(apiId);
    // });
    return this.http.put(`/api/ext/applications/${applicationId}/submitservicesubscription`, body);
  }

  getApiDetail(apiId: string): Observable<any> {
    return this.http.get(`/api/ext/services/${apiId}`).pipe(map((result: ExternalApiDetails) => result));
  }

  createApplication(appData: CreateApplicationData): Observable<any> {
    return this.http.post(`/api/ext/applications`, appData).pipe(map((result: any) => result));
  }

  updateApplicationWebsite(websiteData: UpdateAppWebsiteData): Observable<any> {
    const appData = {
      website: websiteData.website,
    };
    return this.http.put(`/api/ext/applications/${websiteData.appId}`, appData).pipe(map((result: any) => result));
  }

  addProdUri(redirectUriData: any): Observable<any> {
    const appData = {
      prodRedirectUrls: redirectUriData.redirectUrls,
    };
    return this.http.put(`/api/ext/applications/${redirectUriData.appId}`, appData).pipe(map((result: any) => result));
  }

  addSandboxUri(redirectUriData: any): Observable<any> {
    const appData = {
      sandboxRedirectUrls: redirectUriData.redirectUrls,
    };
    return this.http.put(`/api/ext/applications/${redirectUriData.appId}`, appData).pipe(map((result: any) => result));
  }

  createUser(signUpData: SignupData): Observable<any> {
    return this.http.post(`/api/ext/users`, signUpData);
  }

  sendResetPasswordEmal(emailToSend: string): Observable<any> {
    const body = {
      email: emailToSend,
    };
    return this.http.put(`/api/ext/users/flagPassword`, body);
  }

  getExternalApplicationsData(): Observable<CombineApplicationsView> {
    return this.http.get(`/api/ext/applications`).pipe(map((result: CombineApplicationsView) => result));
  }

  sendContactUsEmail(contactUsData: ContactUsData): Observable<any> {
    return this.http.post(`/api/ext/notifications/contactus`, contactUsData).pipe(map((result: any) => result));
  }

  getDownloadDocumentById(serviceId: string, documentId: string): Observable<any> {
    return this.http.get(`/api/ext/services/${serviceId}/documents/${documentId}`);
  }

  unsubscribeAPI(applicationId: string, serviceId: any): Observable<any> {
    return this.http.put(`/api/ext/applications/${applicationId}/unsubscribe/${serviceId}`, undefined);
  }

  getExternalApplicationByID(appId: string): Observable<Application> {
    return this.http.get(`/api/ext/applications/${appId}`).pipe(map((result: Application) => result));
  }

  deleteApplication(appId: string): Observable<any> {
    return this.http.delete(`/api/ext/applications/${appId}`);
  }
  generateClientId(appId: string, environment: any): Observable<any> {
    return this.http.post(`/api/ext/applications/${appId}/client/generate`, environment);
  }
  regenerateClientId(appId: string, environment: any): Observable<any> {
    return this.http.post(`/api/ext/applications/${appId}/client/regenerate`, environment);
  }

  getContactUsCategories(supportRequestApi: string): Observable<any> {
    supportRequestApi = supportRequestApi ? supportRequestApi : 'none';
    return this.http.get(
      `/api/ext/notifications/external/support?supportRequestApi=${encodeURIComponent(supportRequestApi)}`
    );
  }

  setTeamGroup(setTeamGroupAction: any): Observable<any> {
    return this.http.post(`/api/ext/profile-management`, setTeamGroupAction);
  }

  addUserToTeamGroup(addUserToTeamGroup: AddUserToTeamGroupType): Observable<any> {
    return this.http.put(`/api/ext/profile-management`, addUserToTeamGroup);
  }

  removeUserFromTeamGroup(removeUserFromTeamGroup: PromoteRemoveUserInTeamGroup): Observable<any> {
    return this.http.put(`/api/ext/profile-management/leaveGroup`, removeUserFromTeamGroup);
  }
  promoteUserToOwner(promoteUserInTeamGroup: PromoteRemoveUserInTeamGroup): Observable<any> {
    return this.http.put(`/api/ext/profile-management/transferGroupOwnership`, promoteUserInTeamGroup);
  }
}
