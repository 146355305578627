<h1 mat-dialog-title>{{ isOwner ? 'Manage Team Users' : 'Add User' }}</h1>
<div mat-dialog-content>
  <div *ngIf="isOwner">
    <div *ngIf="teamGroupMembers.length">
      <h2>Current Team Members</h2>
      <div class="member-container" *ngFor="let member of teamGroupMembers">
        <span>{{ member.displayName }} ({{ member.email }})</span>
        <div class="add-promote-users-buttons">
          <button
            mat-button
            class="secondary-action-button"
            color="warn"
            (click)="removeGroupMember(member.email, member.displayName, true)">
            Remove User
          </button>
          <button
            mat-button
            class="primary-action-button"
            color="primary"
            (click)="promoteGroupMember(member.email, member.displayName)">
            Promote to Owner
          </button>
        </div>
      </div>
    </div>
    <hr />
  </div>
  <h2>Add Users to the Team</h2>
  <form [formGroup]="userForm">
    <div formArrayName="users">
      <div *ngFor="let user of userControls; let i = index" [formGroupName]="i">
        <mat-form-field>
          <input matInput placeholder="Email" formControlName="email" />
          <button mat-icon-button *ngIf="userControls.length > 1" (click)="removeUser(i)">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <button mat-button class="primary-action-button" (click)="addUser()">Add Another User</button>
  </form>
</div>
<div mat-dialog-actions>
  <div *ngIf="!teamGroupMembers?.length">
    <button mat-button class="secondary-action-button" color="warn" (click)="closeTeam(teamName)">
      Close the Team
    </button>
  </div>
  <button mat-button class="secondary-action-button" (click)="onCancel()">Cancel</button>
  <button
    mat-button
    [ngClass]="{ 'primary-disabled-action-button': isDisabled(), 'primary-action-button': !isDisabled() }"
    [disabled]="isDisabled()"
    (click)="onSubmit()">
    Submit
  </button>
</div>
