<form [formGroup]="teamForm" (ngSubmit)="onCreate()">
  <h1 mat-dialog-title>Create Team</h1>
  <div mat-dialog-content class="full-width">
    <mat-form-field class="full-width">
      <mat-label>Team Name</mat-label>
      <input matInput formControlName="newTeamSpaceName" />
      <mat-error *ngIf="teamForm.get('newTeamSpaceName').hasError('required')"> Team name is required. </mat-error>
      <mat-error *ngIf="teamForm.get('newTeamSpaceName').hasError('pattern')">
        Team name can only contain letters, numbers, spaces, underscores, hyphens, and apostrophes.
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button class="secondary-action-button" mat-button type="button" (click)="onCancel()">Cancel</button>
    <button class="primary-action-button" mat-button type="submit" [disabled]="teamForm.invalid">Create</button>
  </div>
</form>
