import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kp-confirm-team-ownership-change-dialog',
  template: `
    <h1 mat-dialog-title>Transfer Ownership</h1>
    <div mat-dialog-content>
      <p>Are you sure you want to transfer ownership to {{ data.name }} ({{ data.email }})?</p>
    </div>
    <div mat-dialog-actions>
      <button class="secondary-action-button" mat-button (click)="onCancel()">Cancel</button>
      <button class="primary-action-button" mat-button color="primary" (click)="onConfirm()">Accept</button>
    </div>
  `,
})
export class ConfirmTeamOwnershipChangeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmTeamOwnershipChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
