import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kp-confirm-member-removal-dialog',
  template: `<h2 mat-dialog-title>Confirm Removal</h2>
    <mat-dialog-content>
      <p>Are you sure you want to remove {{ data.name }} ({{ data.email }}) from the team ?</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="secondary-action-button" mat-button (click)="onCancel()">Cancel</button>
      <button class="primary-action-button" mat-button (click)="onConfirm()">Confirm</button>
    </mat-dialog-actions>`,
})
export class ConfirmMemberRemovalDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmMemberRemovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
