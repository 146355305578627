import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact-page/contact-page.module').then(m => m.ContactPageModule),
  },
  {
    path: 'console',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/console-page/console-page.module').then(m => m.ConsolePageModule),
  },
  {
    path: 'apis',
    loadChildren: () => import('./pages/apis-page/apis-page.module').then(m => m.ApisPageModule),
  },
  {
    path: 'api-subscribe',
    loadChildren: () =>
      import('./pages/api-subscribe-page/api-subscribe-page.module').then(m => m.ApiSubscribePageModule),
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs-page/faqs-page.module').then(m => m.FaqsPageModule),
  },
  {
    path: 'privacy-policy/KPHA',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule),
  },
  {
    // Needed for hash routing
    path: 'error',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule),
  },
  {
    // Needed for hash routing
    path: 'state',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule),
  },
  {
    // Needed for hash routing
    path: 'app-details',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/application-details-page/application-details-page.module').then(
        m => m.ApplicationDetailsPageModule
      ),
  },
  {
    path: 'profile-edit',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/profile-management/profile-management.module').then(m => m.ProfileManagementModule),
  },
];
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabled' : 'disabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
