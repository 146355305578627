import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import {
  AddUserToTeamGroup,
  PromoteUserToOwner,
  RemoveUserFromTeamGroup,
  // PromoteUserToOwner,
} from 'src/app/store/api-eco.actions';
import { AddUserToTeamGroupType, User } from '../../store';
import { takeUntil, tap } from 'rxjs/operators';
import { ApiEcoState } from '../../store/api-eco.state';
import { Subject } from 'rxjs';
import { ProgressSpinnerDialogService } from '../../services/progress-spinner-dialog.service';
import { ConfirmTeamOwnershipChangeDialogComponent } from '../confirm-team-ownership-change-dialog/confirm-team-ownership-change-dialog.component';
import { ConfirmMemberRemovalDialogComponent } from '../confirm-team-member-removal-dialog/confirm-team-member-removal-dialog.component';
import { ConfirmMemberExitDialogComponent } from '../confirm-team-member-exit-dialog/confirm-group-member-exit-dialog.component';

@Component({
  selector: 'kp-add-user-dialog',
  templateUrl: './add-user-to-team-dialog.component.html',
  styleUrls: ['./add-user-to-team-dialog.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class AddUserToTeamDialogComponent implements OnInit, OnDestroy {
  private destroyed = new Subject();
  userForm: FormGroup;
  teamName: string;
  isOwner: boolean;
  currentUser: User;
  teamGroupMembers: Partial<User>[];

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddUserToTeamDialogComponent>,
    private progressDialogService: ProgressSpinnerDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.teamName = data.teamName;
    this.isOwner = data.isOwner;
    this.teamGroupMembers = data.teamGroupMembers;
  }

  ngOnInit(): void {
    this.store
      .select(ApiEcoState.getUser)
      .pipe(takeUntil(this.destroyed))
      .subscribe((user: User) => {
        if (user) {
          this.currentUser = user;
        }
      });
    this.userForm = this.fb.group({
      users: this.fb.array([this.createUserFormGroup()], [this.minLengthArray(1)]),
    });
  }

  get userControls() {
    return (this.userForm.get('users') as FormArray).controls;
  }

  createUserFormGroup(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  addUser(): void {
    const users = this.userForm.get('users') as FormArray;
    users.push(this.createUserFormGroup());
    this.adjustDialogHeight();
  }

  removeUser(index: number): void {
    const users = this.userForm.get('users') as FormArray;
    users.removeAt(index);
    this.adjustDialogHeight();
  }

  adjustDialogHeight(): void {
    this.dialogRef.updateSize();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  isDisabled(): boolean {
    return !this.userForm.valid || this.userForm.get('users').value.length === 0;
  }
  onSubmit(): void {
    // Remove duplicate emails
    const uniqueEmails: string[] = Array.from(new Set(this.userForm.value.users.map(user => user.email)));

    // Update the form control with the unique emails
    const userFormArray = this.userForm.get('users') as FormArray;
    userFormArray.clear();
    uniqueEmails.forEach(email => {
      userFormArray.push(this.fb.group({ email: [email, [Validators.required, Validators.email]] }));
    });

    // Check if the form is valid and there are no duplicate emails
    if (this.userForm.valid && uniqueEmails.length > 0) {
      const addUserToTeamGroup: AddUserToTeamGroupType = {
        usersToAddEmails: uniqueEmails,
        teamGroupName: this.teamName,
      };

      this.store
        .dispatch(new AddUserToTeamGroup(addUserToTeamGroup))
        .pipe(
          tap(() => {
            // Fetch the updated group members after adding users
            this.store
              .select(ApiEcoState.getUser)
              .pipe(takeUntil(this.destroyed))
              .subscribe((user: User) => {
                this.teamGroupMembers = user?.teamGroupDetails?.teamGroupMembers;
                this.userForm.reset();
                this.userForm.setControl('users', this.fb.array([this.createUserFormGroup()]));
              });
          })
        )
        .subscribe();
    } else {
      // console.log('Form is invalid or no emails present.');
    }
  }

  minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c instanceof FormArray) {
        return c.length >= min ? null : { minLengthArray: true };
      }
      return null;
    };
  }

  removeGroupMember(email: string, name: string, removedByOwner = false): void {
    const dialogRef = this.dialog.open(ConfirmMemberRemovalDialogComponent, {
      width: '950px',
      data: { email, name },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store
          .dispatch(
            new RemoveUserFromTeamGroup({
              email,
              teamGroupName: this.teamName,
              teamGroupId: this.data.teamId,
              removedByOwner,
            })
          )
          .pipe(
            tap(() => {
              // Fetch the updated group members after adding users
              this.store
                .select(ApiEcoState.getUser)
                .pipe(takeUntil(this.destroyed))
                .subscribe((user: User) => {
                  this.teamGroupMembers = user.teamGroupDetails.teamGroupMembers;
                });
            })
          )
          .subscribe();
      } else {
        dialogRef.close();
      }
    });
  }

  closeTeam(teamGroupName: string) {
    const dialogRef = this.dialog.open(ConfirmMemberExitDialogComponent, {
      width: '950px',
      data: { teamGroupName },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store
          .dispatch(
            new RemoveUserFromTeamGroup({
              email: this.currentUser.email,
              teamGroupName: this.currentUser.teamGroupDetails.teamGroupName,
              teamGroupId: this.currentUser.teamGroupDetails._id,
              removedByOwner: false,
            })
          )
          .pipe(
            tap(() => {
              // Fetch the updated group members after adding users
              this.store
                .select(ApiEcoState.getUser)
                .pipe(takeUntil(this.destroyed))
                .subscribe((updatedUser: User) => {
                  this.currentUser = updatedUser;
                  this.dialogRef.close();
                });
            })
          )
          .subscribe();
      }
    });
  }

  promoteGroupMember(email: string, name: string): void {
    const dialogRef = this.dialog.open(ConfirmTeamOwnershipChangeDialogComponent, {
      width: '950px',
      data: { email, name },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // User accepted
        this.store
          .dispatch(
            new PromoteUserToOwner({
              email,
              teamGroupName: this.teamName,
              teamGroupId: this.data.teamId,
            })
          )
          .pipe(
            tap(() => {
              // Fetch the updated group members after adding users
              this.store
                .select(ApiEcoState.getUser)
                .pipe(takeUntil(this.destroyed))
                .subscribe((user: User) => {
                  this.teamGroupMembers = user?.teamGroupDetails?.teamGroupMembers;
                });
            })
          )
          .subscribe();
        this.dialogRef.close();
      } else {
        // User canceled
        dialogRef.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next('');
    this.destroyed.complete();
  }
}
