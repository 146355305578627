export interface ApiEcosystemModel {
  [x: string]: any;
  authData: AuthData;
  selectedRoute: string;
  showLoginView: string; // login or email code
  showNavbarText: boolean;
  signupData: SignupData;
  loginData: LoginData;
  apis: ExternalApiDetails[];
  applications: CombineApplicationsView;
  selectedApiDetail: ExternalApiDetails;
  selectedApplicationDetail: Application;
  notifications: Notification[];
  user: User;
  resetPasswordEmail: string;
  downloadDocumentURI: string;
  clientSecret?: string;
  contactUsCategories?: string[];
}

export const defaultState: ApiEcosystemModel = {
  selectedRoute: null,
  showLoginView: 'Login',
  showNavbarText: true,
  signupData: {},
  loginData: {},
  apis: [],
  applications: {},
  selectedApiDetail: undefined,
  selectedApplicationDetail: undefined,
  authData: {
    access_token: '',
    expires_in: undefined,
    id_token: '',
    refresh_token: '',
    token_type: '',
  },
  notifications: [],
  user: {
    _id: '',
    department: '',
    displayName: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    nuid: '',
    email: '',
    isAuthenticated:
      JSON.parse(localStorage.getItem('apie.local.global_context'))?.status === 'loggedIn' ||
      JSON.parse(localStorage.getItem('apie.local.global_context'))?.status === 'loggedInPendingRefresh',
    isLoggedOff: false,
    modified: false,
    userRole: undefined,
    userToken: '',
    country: '',
  },
  resetPasswordEmail: '',
  downloadDocumentURI: '',
  contactUsCategories: undefined,
};

export interface Notification {
  error: boolean;
  warning: boolean;
  message: string;
  title: string;
  duration?: number;
}

export interface User {
  _id: string;
  department?: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  nuid?: string;
  email: string;
  isAuthenticated?: boolean;
  isLoggedOff?: boolean;
  modified?: boolean;
  userRole?: string;
  pending?: boolean;
  id?: string;
  status?: string;
  country?: string;
  userToken?: string;
  teamGroupDetails?: {
    _id?: string;
    teamGroupName?: string;
    teamGroupDomain?: string;
    isOwner?: boolean;
    teamGroupMembers?: Partial<User>[];
    emailsNotInUsers?: string[];
  };
  teamGroup?: any;
}

export interface UpdateUserData {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  organizationName?: string;
  country?: string;
  homeAccountId?: string;
  idToken?: string;
  phoneUpdateOnly?: boolean;
}
export interface SsoUser {
  Department?: string;
  DisplayName?: string;
  FirstName?: string;
  LastName?: string;
  'Job Title'?: string;
  sub?: string;
  Email: string;
}

export interface ApiSubscription {
  api?: string;
  emailUpdates?: boolean;
  requestStatus?: string;
}

export interface ConnectionRequest {
  serviceId?: string;
  serviceOwnerEmail?: string;
  externalServiceName?: string;
  serviceDescription?: string;
  receiveEmailNotifications?: boolean;
  status?: string;
  approved?: boolean;
  sandboxEndpoint?: string;
  productionEndpoint?: string;
  limitedServiceAvailability?: boolean;
  note?: string;
}

export interface Application {
  id: string;
  name: string;
  description: string;
  portalClassification?: string;
  version?: string;
  subscriptions?: ApiSubscription[];
  website: string;
  connectionRequestList?: ConnectionRequest[];
  sandboxRedirectUrls?: string[];
  prodRedirectUrls?: string[];
  owner?: string;
  canGenerateCredentials?: boolean;
  isSubscribed?: boolean;
  hasRevoked?: boolean;
  sandboxClientId?: string;
  sandboxClientSecret?: string;
  prodClientId?: string;
  prodClientSecret?: string;
}

export interface CombineApplicationsView {
  groupApplications?: Application[];
  personalApplications?: Application[];
}

export interface CreateApplication {
  name: string;
  description: string;
  website: string;
}

export interface AuthData {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  token_type: string;
}

export interface LoginData {
  email?: string;
  homeAccountId?: string;
  updateUserData?: UpdateUserData;
  idToken?: string;
}
export interface SignupData {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  organizationName?: string;
  organizationWebsite?: string;
  phoneNumber?: string;
  country?: string;
  homeAccountId?: string;
  idToken?: string;
}

export interface CommonEmailData {
  serviceTitle?: string;
  freeText?: string;
  helpCategory?: string;
}

export interface EmailContent {
  displayName?: string;
  requesterEmail?: string;
}

export interface ContactUsData {
  commonEmailData: CommonEmailData;
  emailContent: EmailContent[];
  firstName?: string;
  lastName?: string;
  piiPhiAttest?: boolean;
}

export interface HttpErrorResponse {
  id: string;
  name: string;
  description: string;
  fhirVersion?: string;
  notes?: any[];
  service: ServiceDetails;
  visibility: string;
  sandboxEndpoint?: string;
  productionEndpoint?: string;
  serviceId?: string;
  isSubscribed?: boolean; // Used to disable apis in Add API list in app-details that have already been requested
}

export interface ExternalApiDetails {
  id: string;
  name: string;
  description: string;
  fhirVersion?: string;
  notes?: any[];
  service: ServiceDetails;
  visibility: string;
  sandboxEndpoint?: string;
  productionEndpoint?: string;
  serviceId?: string;
  isSubscribed?: boolean; // Used to disable apis in Add API list in app-details that have already been requested
}

export interface ServiceDetails {
  name?: string;
  description?: string;
  version?: string;
  keywords?: string[];
  operations?: string[];
  interfaceType?: string;
  notes?: any[];
  externalAttachments?: ExternalAttachments[];
}

export interface ExternalAttachments {
  id: string;
  name: string;
  tag: string;
  fileDisplayName: string;
}

export interface ClientSecretData {
  clientSecretType: string;
  clientSecret: string;
}

export interface CreateApplicationData {
  name: string;
  description: string;
  website: string;
}

export interface UpdateAppWebsiteData {
  website: string;
  appId: string;
}
export interface UriData {
  redirectUrls: string[];
  appId: string;
  isProd: boolean;
}
export interface GenerateClientIdData {
  appId: string;
  isPrimary: boolean;
}

export interface BannerData {
  bannerType: string;
  bannerText?: string;
}

export interface SandboxAvailabilityData {
  sandboxUnderConstruction: boolean;
  sandboxUnderConstructionText?: string;
}

export enum AttachmentTypeTag {
  OPENAPICONFIGURATION = 'openapi-specification',
  IMPLEMENTATIONGUIDE = 'implementation-guide',
  ADDITIONALINFORMATION = 'additional-information',
  OTHER = 'other',
}

export enum BannerTypes {
  UNDERCONSTRUCTION = 'under-construction',
  NOTES = 'notes',
  NONE = 'none',
}

export interface AddUserToTeamGroupType {
  usersToAddEmails: string[];
  teamGroupName: string;
}

export interface PromoteRemoveUserInTeamGroup {
  email?: string;
  teamGroupName?: string;
  teamGroupId?: string;
  removedByOwner?: boolean;
}
