import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TitleService } from 'src/app/services/title.service';
import { Application, CombineApplicationsView, ConnectionRequest, ExternalApiDetails } from '../../store';
import { ApiEcoState } from '../../store/api-eco.state';

@Component({
  selector: 'kp-api-subscribe-page',
  templateUrl: './api-subscribe-page.component.html',
  styleUrls: ['./api-subscribe-page.component.scss'],
})
export class ApiSubscribePageComponent implements OnInit, OnDestroy {
  selectedApiDetails: ExternalApiDetails;
  applicationsData: Application[];
  private destroyed = new Subject();

  constructor(
    private store: Store,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.titleService.setDocTitle('Add API: Select Applications to Use API | Kaiser Permanente Developer Portal');
    combineLatest([
      this.store.select(ApiEcoState.getSelectedApiDetail),
      this.store.select(ApiEcoState.getExternalApplications),
    ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(([selectedAPI, applications]: [ExternalApiDetails, CombineApplicationsView]) => {
        this.selectedApiDetails = selectedAPI;
        this.applicationsData = [];

        const processApplications = (apps: Application[], isGroup: boolean = false): void => {
          apps.forEach((app: Application) => {
            const processedApp: Application = {
              ...app,
              hasRevoked: !!app.connectionRequestList.find(
                (connection: ConnectionRequest) => connection.status.toLowerCase() === 'revoked'
              ),
              isSubscribed: !!app.connectionRequestList?.find(
                (connection: ConnectionRequest) => connection.serviceId === selectedAPI.id
              ),
            };
            if (isGroup) {
              processedApp.name += ' (Group Application)';
            }
            this.applicationsData.push(processedApp);
          });
        };
        processApplications(applications.personalApplications);
        if (applications?.groupApplications?.length) {
          processApplications(applications.groupApplications, true);
        }
      });
  }

  ngOnDestroy(): void {
    this.titleService.setDocTitle('api-ecosystem');
  }
}
