import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kp-confirm-member-exit-dialog',
  template: `<h2 mat-dialog-title>Looks like you want to exit the team</h2>
    <mat-dialog-content>
      <p>Are you sure you want to leave {{ data.teamGroupName }}?</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="secondary-action-button" mat-button (click)="onCancel()">Cancel</button>
      <button class="primary-action-button" mat-button (click)="onConfirm()">Confirm</button>
    </mat-dialog-actions>`,
  styleUrls: ['./confirm-group-member-exit-dialog.component.scss'],
})
export class ConfirmMemberExitDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmMemberExitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
