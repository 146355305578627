import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ApiEcoState } from './store/api-eco.state';
import { NgxsModule } from '@ngxs/store';
import { HeaderComponent } from './components/header/header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DialogModule } from 'primeng/dialog';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule } from 'ng-recaptcha';
import { TermsDialogModule } from './components/global-dialog/global-dialog.module';
import { ApiSubscribePageComponent } from './pages/api-subscribe-page/api-subscribe-page.component';
import { ApiSubscribeComponent } from './components/api-subscribe/api-subscribe.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FooterComponent } from './components/footer/footer.component';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { environment as envConfigs } from 'src/environments/environment';
import { ProgressSpinnerModule } from './components/progress-spinner/progress-spinner.module';
import { BrowserStorageService } from './services/browser-storage.service';
import { UserContextService } from './services/user-context.service';
import { APIELogService } from './services/apie-log.service';
import { CreateTeamDialogComponent } from './components/create-team-dialog/create-team-dialog.component';
import { AddUserToTeamDialogComponent } from './components/add-user-to-team-dialog/add-user-to-team-dialog.component';
import { ConfirmTeamOwnershipChangeDialogComponent } from './components/confirm-team-ownership-change-dialog/confirm-team-ownership-change-dialog.component';
import { ConfirmMemberRemovalDialogComponent } from './components/confirm-team-member-removal-dialog/confirm-team-member-removal-dialog.component';
import { ConfirmMemberExitDialogComponent } from './components/confirm-team-member-exit-dialog/confirm-group-member-exit-dialog.component';

export const loggerCallback = (logLevel: LogLevel, message: string) => {
  console.log(message);
};

export const msalInstanceFactory = (): IPublicClientApplication =>
  new PublicClientApplication({
    auth: {
      clientId: envConfigs.clientId,
      authority: envConfigs.b2cPolicies.authorities.signIn.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      knownAuthorities: [envConfigs.b2cPolicies.authorityDomain],
    },
    cache: {
      cacheLocation:
        envConfigs.userContext && envConfigs.userContext.userCacheLocation
          ? envConfigs.userContext.userCacheLocation
          : BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: envConfigs.isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });

export const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, Array<string>>();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};

export const msalGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: [],
  },
});

export const getBaseUrl = () => document.getElementsByTagName('base')[0].href;

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderComponent,
    ApiSubscribePageComponent,
    ApiSubscribeComponent,
    FooterComponent,
    CreateTeamDialogComponent,
    AddUserToTeamDialogComponent,
    ConfirmTeamOwnershipChangeDialogComponent,
    ConfirmMemberRemovalDialogComponent,
    ConfirmMemberExitDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxsModule.forRoot([ApiEcoState], { developmentMode: !environment.production }),
    OverlayPanelModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      timeOut: 1500,
      easeTime: 900,
      preventDuplicates: true,
    }),
    AppRoutingModule,
    MatDialogModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatAutocompleteModule,
    DialogModule,
    MatSelectModule,
    TermsDialogModule,
    RecaptchaModule,
    MatCheckboxModule,
    MsalModule,
    ProgressSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6Ldue9MaAAAAAD99nQJ066U9Bkz3WDuzigL-LaV0' } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    BrowserStorageService,
    UserContextService,
    APIELogService,
    Title,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
