import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'kp-create-team-dialog',
  templateUrl: './create-team-dialog.component.html',
  styleUrls: ['./create-team-dialog.component.scss'],
})
export class CreateTeamDialogComponent {
  teamForm: FormGroup;
  newTeamSpaceName = '';

  constructor(
    public dialogRef: MatDialogRef<CreateTeamDialogComponent>,
    private fb: FormBuilder
  ) {
    this.teamForm = this.fb.group({
      newTeamSpaceName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 _'-]*$/)]],
    });
  }

  onCreate(): void {
    if (this.teamForm.valid) {
      this.dialogRef.close(this.teamForm.value.newTeamSpaceName);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
